export const TELEGRAM_URL = /(https?:\/\/)?(www[.])?(telegram|t)\.me\/([a-zA-Z0-9_-]*)\/?/;

export const DAY_IN_MS = 86_400_000;

export const PAYMENT_AMOUNT_LIMIT = { min: 1_500 };

export const RUSSIA_COUNTRY_ID = 643;

export const TELEGRAM_CAPTION_MAX_LENGTH = 1024;

export const TELEGRAM_TEXT_MAX_LENGTH = 4096;

export const TEXT_CONTENT_MIN_LENGTH = 8;

export const BUTTONS_COUNT_LIMIT = 100;

export const TELEGRAM_STORIES_LIMIT = 3;

export const UPLOAD_ATTACHMENTS_LIMIT = 10;
export const ATTACHMENT = {
  ['image']: 'image',
  ['video']: 'video',
} as const;

export const COMMENT_PLACEHOLDER = 'Укажите детали и пожелания, если необходимо';

export const enum Role {
  'ROLE_USER' = 1,
  'ROLE_ADMIN' = 2,
  'ROLE_PUBLISHER' = 3,
  'ROLE_DATA_LIGHT' = 10,
}

export const emptyCallback = () => {
  //
};

export const ACQUIRING_AMOUNT_MIN = 500;

export const ACQUIRING_AMOUNT_MAX = 300_000;

export const IMAGE_REG_EXP = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i;
